import type { TenantContext } from 'app-model.carbon-saver'
import { injectable } from '../../../di'
import { axiosApi } from '../../../resources'

export class TenantContextResource {
  public static injectable = injectable(TenantContextResource)

  async readTenantContext (): Promise<TenantContext> {
    const response = await axiosApi.get('/tenant-context')
    const tenantContext = response.data as TenantContext
    return tenantContext
  }
}
