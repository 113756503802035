import type { TenantConfigurationResource } from '../resources/tenant-configuration'
import type {
  TenantConfigurationGeneralInformation,
  TenantConfigurationGeneralInformationUpdateRequest
} from 'app-model.carbon-saver'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { refDefault } from '@vueuse/core'

export interface UseGeneralInformation {
  generalInformationOptional: Ref<TenantConfigurationGeneralInformation | undefined>
  generalInformation: Ref<TenantConfigurationGeneralInformation>
  readGeneralInformation: UseAsyncRunner<TenantConfigurationGeneralInformation>
  updateGeneralInformation: UseAsyncRunner<TenantConfigurationGeneralInformation, [TenantConfigurationGeneralInformationUpdateRequest]>
}

export interface UseGeneralInformationOptions {
  resource: TenantConfigurationResource
}

export function useGeneralInformation (options: UseGeneralInformationOptions): UseGeneralInformation {
  const { resource } = options

  const generalInformationOptional = ref<TenantConfigurationGeneralInformation>()
  const generalInformation = refDefault<TenantConfigurationGeneralInformation>(generalInformationOptional,
    { displayName: '' }
  )

  const readGeneralInformation = useAsyncRunner(async () => await resource.readGeneralInformation(), generalInformationOptional)

  const updateGeneralInformation = useAsyncRunner(async (request: TenantConfigurationGeneralInformationUpdateRequest) => await resource.updateGeneralInformation(request), generalInformationOptional)

  return {
    generalInformationOptional,
    generalInformation,
    readGeneralInformation,
    updateGeneralInformation
  }
}
