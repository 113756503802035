import type { TenantConfigurationResource } from '../resources/tenant-configuration'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { ComputedRef, Ref } from 'vue'
import { computed, ref, watch } from 'vue'
import type { TenantConfigurationCustomI18nResource, TenantConfigurationCustomI18nResourceKey } from 'app-model.carbon-saver'
import type { Language } from '../../../use/i18n'
import { useI18n } from '../../../use/i18n'
import i18next from 'i18next'
import { useApp } from '../../../use/app'

export interface CustomI18nResource {
  ns: string
  resource: string
  transient?: boolean
}

export interface CustomI18nResourceLanguage {
  language: Language
  resources: CustomI18nResource[]
}

export interface UseCustomI18nResource {
  languageResources: Ref<CustomI18nResourceLanguage[]>
  languages: ComputedRef<Language[]>
  resources: ComputedRef<string[]>
  loadResources: UseAsyncRunner<CustomI18nResourceLanguage[]>
  saveResources: UseAsyncRunner<void>
  createResource: UseAsyncRunner<TenantConfigurationCustomI18nResource, [language: string, ns: string]>
  updateResource: UseAsyncRunner<TenantConfigurationCustomI18nResource, [language: string, ns: string, jsonContent: string]>
  deleteResource: UseAsyncRunner<TenantConfigurationCustomI18nResource, [language: string, ns: string]>
  init18next: UseAsyncRunner<void, [refresh?: boolean]>
}

export interface UseCustomI18nResourceOptions {
  resource: TenantConfigurationResource
}

export function useCustomI18nResource (options: UseCustomI18nResourceOptions): UseCustomI18nResource {
  const { supportedLanguages } = useI18n()
  const languageResources: Ref<CustomI18nResourceLanguage[]> = ref(supportedLanguages.map((supportedLanguage) => ({
    language: supportedLanguage,
    resources: []
  })))

  const { resource } = options

  const loadedBundles: Map<string, string> = new Map()

  const languages = computed(() => {
    return languageResources.value.map(lr => lr.language)
  })

  const resources = computed(() => {
    const ret = [...new Set(languageResources.value.flatMap(lr => lr.resources).map(resource => resource.ns))]
    ret.sort()
    return ret
  })

  const loadResources = useAsyncRunner(async () => {
    const newLanguageResources: CustomI18nResourceLanguage[] = []

    for (const language of languageResources.value.map(r => r.language)) {
      const newLanguageResource: CustomI18nResourceLanguage = {
        language,
        resources: []
      }
      newLanguageResources.push(newLanguageResource)

      const resourceKeys = await resource.listCustomI18nResource(language.value)

      const promises: Promise<string>[] = []
      for (const resourceKey of resourceKeys) {
        promises.push(resource.readCustomI18nResourceText(resourceKey.language, resourceKey.ns))
      }

      const resources: string[] = []
      if (promises.length > 0) {
        resources.push(...await Promise.all(promises))
      }

      for (const [index, key] of resourceKeys.entries()) {
        const resource = resources[index]
        newLanguageResource.resources.push({
          ns: key.ns,
          resource
        })
      }
    }

    return newLanguageResources
  }, languageResources)

  watch(languageResources, (languageResourcesValue) => {
    // Add missing resources with empty content
    for (const languageResource of languageResourcesValue) {
      const toCreateResources = [...resources.value]
      for (const resource of languageResource.resources) {
        const index = toCreateResources.indexOf(resource.resource)
        if (index > 0) {
          toCreateResources.splice(index, 1)
        }
      }
      for (const toCreateResource of toCreateResources) {
        languageResource.resources.push({
          ns: toCreateResource,
          resource: ''
        })
      }
    }
  }, { immediate: true })

  const saveResources = useAsyncRunner(async () => {
    for (const language of languages.value) {
      for (const ns of resources.value) {
        const c = languageResources.value.find(lr => lr.language.value === language.value)
        if (c) {
          const n = c.resources.find(n => n.ns === ns)
          if (n) {
            if (n.resource) {
              await updateResource.run(language.value, n.ns, n.resource)
            } else {
              await deleteResource.run(language.value, n.ns)
              const index = languageResources.value.indexOf(c)
              if (index > 0) {
                languageResources.value.splice(index, 1)
              }
            }
          }
        }
      }
    }
  })

  const createResource = useAsyncRunner(async (language: string, ns: string): Promise<TenantConfigurationCustomI18nResource> => {
    return await resource.createCustomI18nResource(language, ns)
  })

  const updateResource = useAsyncRunner(async (language: string, ns: string, jsonContent: string): Promise<TenantConfigurationCustomI18nResource> => {
    return await resource.updateCustomI18nResource(language, ns, jsonContent)
  })

  const deleteResource = useAsyncRunner(async (language: string, ns: string): Promise<TenantConfigurationCustomI18nResource> => {
    return await resource.deleteCustomI18nResource(language, ns)
  })

  const init18next = useAsyncRunner(async (refresh?: boolean) => {
    if (refresh) {
      for (const key of Object.keys(localStorage)) {
        if (key.startsWith('tenant.custom-i18n-resource')) {
          localStorage.removeItem(key)
        }
      }
    }

    const listKey = 'tenant.custom-i18n-resource.list'
    const listString = localStorage.getItem(listKey)
    let list: TenantConfigurationCustomI18nResourceKey[]
    if (listString === null || refresh) {
      list = await resource.listCustomI18nResource()
    } else {
      list = JSON.parse(listString)
    }
    localStorage.setItem(listKey, JSON.stringify(list))

    const languageResources: Map<string, Set<string>> = new Map()
    for (const entry of list) {
      let namespaces = languageResources.get(entry.language)
      if (!namespaces) {
        namespaces = new Set()
        languageResources.set(entry.language, namespaces)
      }
      namespaces.add(entry.ns)
    }

    const bundleEntries: { key: string, language: string, ns: string, bundle: string }[] = []
    for (const language of supportedLanguages) {
      const namespaces = languageResources.get(language.value)
      for (const ns of ['app'].filter(ns => !!namespaces?.has(ns))) {
        const key = `tenant.custom-i18n-resource.${language.value}.${ns}`
        const bundleEntry: { key: string, language: string, ns: string, bundle: string } = {
          key,
          language: language.value,
          ns,
          bundle: ''
        }
        const cachedBundleText = localStorage.getItem(key)
        if (cachedBundleText && !refresh) {
          bundleEntry.bundle = cachedBundleText
        } else {
          const bundleText = await resource.readCustomI18nResourceText(language.value, ns)
          bundleEntry.bundle = bundleText
          if (cachedBundleText !== bundleText) {
            localStorage.setItem(key, bundleText)
          }
        }

        if (bundleEntry.bundle) {
          bundleEntries.push(bundleEntry)
        }
      }
    }

    if (bundleEntries.length > 0) {
      let shouldUpdateKeyIndex = false
      for (const bundleEntry of bundleEntries) {
        const loadedBundle = loadedBundles.get(bundleEntry.key)
        if (loadedBundle !== bundleEntry.bundle) {
          shouldUpdateKeyIndex = true
          i18next.addResourceBundle(bundleEntry.language, bundleEntry.ns, JSON.parse(bundleEntry.bundle), true, true)
          loadedBundles.set(bundleEntry.key, bundleEntry.bundle)
        }
      }

      if (shouldUpdateKeyIndex) {
        const { nextKeyIndex } = useApp()
        nextKeyIndex()
      }
    }
  })

  return {
    languageResources,
    languages,
    resources,
    loadResources,
    saveResources,
    createResource,
    updateResource,
    deleteResource,
    init18next
  }
}
