import type { TenantContext } from 'app-model.carbon-saver'
import type { TenantContextResource } from '../resources/tenant-context'
import type { UseAsyncRunner } from '../../../use/async-runner'
import { useAsyncRunner } from '../../../use/async-runner'
import type { Ref } from 'vue'
import { ref } from 'vue'
import { refDefault } from '@vueuse/core'

export interface UseTenantContext {
  tenantContextOptional: Ref<TenantContext | undefined>
  tenantContext: Ref<TenantContext>
  readTenantContext: UseAsyncRunner<TenantContext>
}

export interface UseTenantContextOptions {
  resource: TenantContextResource
}

export function useTenantContext (options: UseTenantContextOptions): UseTenantContext {
  const { resource } = options

  const tenantContextOptional = ref<TenantContext>()
  const tenantContext = refDefault<TenantContext>(tenantContextOptional, {
    generalInformation: {
      displayName: ''
    },
    members: []
  })

  const readTenantContext = useAsyncRunner(async () => await resource.readTenantContext(), tenantContextOptional)

  return {
    tenantContextOptional,
    tenantContext,
    readTenantContext
  }
}
