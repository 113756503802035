import { injectable } from '../../../di'
import { axiosApi } from '../../../resources'
import type {
  TenantConfigurationCustomI18nResource,
  TenantConfigurationCustomI18nResourceKey,
  TenantConfigurationGeneralInformation,
  TenantConfigurationGeneralInformationUpdateRequest
} from 'app-model.carbon-saver'

export class TenantConfigurationResource {
  public static injectable = injectable(TenantConfigurationResource)

  async readGeneralInformation (): Promise<TenantConfigurationGeneralInformation> {
    const response = await axiosApi.get('/tenant-configuration/general-information')
    return response.data
  }

  async updateGeneralInformation (data: TenantConfigurationGeneralInformationUpdateRequest): Promise<TenantConfigurationGeneralInformation> {
    const response = await axiosApi.put('/tenant-configuration/general-information', data)
    return response.data
  }

  async listCustomI18nResource (language?: string): Promise<TenantConfigurationCustomI18nResourceKey[]> {
    const response = await axiosApi.get('/tenant-configuration/custom-i18n-resource' + (language ? `/${language}` : ''))
    return response.data
  }

  async readCustomI18nResource (language: string, ns: string): Promise<TenantConfigurationCustomI18nResource> {
    const response = await axiosApi.get(`/tenant-configuration/custom-i18n-resource/${language}/${ns}`)
    return response.data
  }

  async readCustomI18nResourceText (language: string, ns: string): Promise<string> {
    const response = await axiosApi.get(`/tenant-configuration/custom-i18n-resource/${language}/${ns}/text`, {
      transformResponse: [(data) => { return data }],
      responseType: 'text'
    })
    return response.data
  }

  async createCustomI18nResource (language: string, ns: string): Promise<TenantConfigurationCustomI18nResource> {
    const response = await axiosApi.post(`/tenant-configuration/custom-i18n-resource/${language}/${ns}`, undefined, { headers: { 'content-type': 'application/json' } })
    return response.data
  }

  async updateCustomI18nResource (language: string, ns: string, jsonContent: string): Promise<TenantConfigurationCustomI18nResource> {
    const response = await axiosApi.put(`/tenant-configuration/custom-i18n-resource/${language}/${ns}`, jsonContent, {
      transformRequest: [(data) => { return data }],
      headers: { 'Content-Type': 'text/plain' }
    })
    return response.data
  }

  async deleteCustomI18nResource (language: string, ns: string): Promise<TenantConfigurationCustomI18nResource> {
    const response = await axiosApi.delete(`/tenant-configuration/custom-i18n-resource/${language}/${ns}`)
    return response.data
  }
}
