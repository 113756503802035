import { injectable, injectRequired } from '../../../di'
import type { UseTenantContext } from '../use/tenant-context'
import { useTenantContext } from '../use/tenant-context'
import { TenantContextResource } from '../resources/tenant-context'

export class TenantContextState {
  public static injectable = injectable(TenantContextState)
  public readonly use: UseTenantContext

  constructor () {
    const contextResource = injectRequired(TenantContextResource)

    this.use = useTenantContext({ resource: contextResource })
  }
}
