import { injectable, injectRequired } from '../../../di'
import { TenantConfigurationResource } from '../resources/tenant-configuration'
import type { UseGeneralInformation } from '../use/general-information'
import { useGeneralInformation } from '../use/general-information'
import type { UseCustomI18nResource } from '../use/custom-18n-resource'
import { useCustomI18nResource } from '../use/custom-18n-resource'

export class TenantConfigurationState {
  public static injectable = injectable(TenantConfigurationState)

  public readonly useGeneralInformation: UseGeneralInformation
  public readonly useCustomI18nResource: UseCustomI18nResource

  constructor () {
    const resource = injectRequired(TenantConfigurationResource)

    this.useGeneralInformation = useGeneralInformation({ resource })
    this.useCustomI18nResource = useCustomI18nResource({ resource })
  }
}
