import { useI18n } from './i18n'
import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'

export interface UseApp {
  keyIndex: Ref<number>
  nextKeyIndex: () => void
}

let _useApp: UseApp | undefined

export function useApp (): UseApp {
  if (_useApp === undefined) {
    const { currentLanguage } = useI18n()
    const currentLanguageKey = computed(() => currentLanguage.value.value)

    const keyIndex = ref(1)

    const nextKeyIndex = () => {
      keyIndex.value++
    }

    watch(currentLanguageKey, () => nextKeyIndex())

    _useApp = {
      keyIndex,
      nextKeyIndex
    }
  }
  return _useApp
}
